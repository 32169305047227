import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day. `}<em parentName="p">{`*`}{`If you are competing in the Pre Open and have missed one of
the wods today’s the day to make it up!`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports
Nutrition seminar with Dawn from Sports Nutrition 2 Go in Cincinnati.
Want to learn the exact number of calories your body burns each day?
Metabolic Testing will be offered before and after the seminar as well!
We will need 20 people in order to have this seminar so please email
Daniel\\@crossfitlouisvilleeast.com if you’d like to attend. \\$35/person
for the seminar.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      